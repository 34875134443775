<template>
  <div class="collect-info">
    <van-tabs @click="timeChange">
      <van-tab title="当日"></van-tab>
      <van-tab title="本周"></van-tab>
      <van-tab title="本月"></van-tab>
      <van-tab title="自定义"></van-tab>
    </van-tabs>
    <van-row style="padding-top: 12px;position:relative;">
        <van-col span="24">
          <van-popover
              v-model="showPopover"
              trigger="click"
              :actions="actions"
              placement="bottom-start"
              @select="onSelect"
          >
            <template #reference>
              <van-button block icon="shop">{{actionText}}</van-button>
<!--              <van-field
                  readonly
                  clickable
                  :border="false"
                 right-icon="arrow-down"
                  name="natTime"
                  :value="actionText"
                  placeholder="选择酒店"
                  @click="showPopover = true"
              />-->
            </template>
          </van-popover>
        </van-col>
        <van-col span="24">
          <!--        <van-search
                      v-model="searchKey"
                      show-action
                      shape="round"
                      placeholder="请输入搜索关键词"
                      @search="onSearch"
                  >
                    <template #action>
                      <div @click="onSearch">搜索</div>
                    </template>
                  </van-search>-->
          <van-search
              v-model="searchKey"
              show-action
              label=""
              placeholder="请输入行程关键词搜索"
              @search="onSearch"
          >
            <template #action>
              <div @click="onSearch">搜索</div>
            </template>
          </van-search>
        </van-col>

    </van-row>
    <div class="des">共{{total}}条记录</div>
    <div class="list-content">
      <van-list
          :immediate-check="false"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          @load="onLoad"
      >
        <div class="check-list" v-for="(item,index) in list" :key="index" >
          <div>
            <div class="item-choose">
            <span>
                        {{index+1}}
                      </span>
            </div>
            <div class="item-info">
              {{hotels[item.infoId]?hotels[item.infoId].name:""}}
            </div>
          </div>
          <div>
            <div class="item-choose">
            <span></span>
            </div>
            <div class="item-name">
              {{item.name}}
              <span style="float:right;">
                <span v-if="item.isComplete=='1'"><van-tag type="warning">退房</van-tag></span>
              <span v-else><van-tag type="success">入住</van-tag></span>
              </span>

            </div>

            <div class="item-check">
              <div style="font-size: 0.6rem;">{{item.collectTime?item.collectTime.substr(0,16):"--"}}</div>
            </div>

            <div class="item-btn" @click="viewDetail(item)">
              <!--            {{parseResult(item)}}-->
              {{item.travelCities}}
              <span class="item-click"><van-icon name="arrow" /></span>
            </div>
          </div>

        </div>

      </van-list>
    </div>
    <div class="list-btn">
      <van-row>
        <van-col span="8">
          <van-button size="large" @click="downLoad(0)">导出当日</van-button>
        </van-col>
        <van-col span="8">
          <van-button  size="large"   @click="downLoad(1)">导出本周</van-button>
        </van-col>
        <van-col span="8">
          <van-button size="large"  @click="downLoad(2)">导出本月</van-button>
        </van-col>
      </van-row>
    </div>
    <van-calendar v-model="calendarShow" type="range" @confirm="onConfirm" :min-date="minDate" :max-range="365" :style="{ height: '500px' }"  />
    <!--    整改纪录闭环弹框-->
    <van-popup v-model="recordPopup" position="right" :style="{ height: '100%',width:'100%' }"  get-container="#app">
      <div class="model-title align-center">
        <van-icon name="arrow-left" size="28" @click="recordPopup=false" style="position:absolute;left: 10px;top: 6px;" />
        {{recordTitle}}
      </div>
      <detail :recordData="recordData" :dict="hotels" ref="record" @closeReport="closeReport"></detail>
    </van-popup>
  </div>
</template>

<script>
import {formatDate,getStartEndByMonth,getWeekEndDate,getRecentMonth} from "@/api/hotelTime"
import detail from "./checkForm"
import {
  PullRefresh,List,Cell,Col,Tag, Row,Icon,Popup,Checkbox,Button,Notify,Tab, Tabs,Calendar,Field,Picker,Search,Popover,
} from 'vant';
export default {
  name: "list",
  components:{detail,
    [Calendar.name]: Calendar,
    [Popover.name]: Popover,
    [Search.name]: Search,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Button.name]: Button,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Row.name]: Row,
    [Col.name]: Col,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [Checkbox.name]: Checkbox,
    [Notify.name]: Notify,
    [Tag.name]: Tag,
    [Field.name]: Field,
    [Picker.name]: Picker,
    [Icon .name]: Icon
  },
  props:{

  },
  watch:{

  },
  data(){
    return {
      params:{
        size: 4,
        page: 0,
        infoId:undefined,
        startTime:undefined,
        endTime:undefined,
        // isComplete:0,
        name:"",
        travelCities:""
      },
      hotels:{},
      calendarShow:false,
      minDate:new Date(2021,9,1),
      error:false,
      loading:false,
      list:[],
      total:0,
      finished:false,
      refreshing:false,
      recordPopup:false,
      recordTitle:"新增",
      // 搜索
      searchKey:"",
      checked:{},
      recordData:{
        id:"",
        name:"",
        remark:""
      },
      showPopover:false,
      // 通过 actions 属性来定义菜单选项
      actions: [{ text: '全部酒店',id:'' }],
      actionText:"全部酒店"
    }
  },
  methods:{
    // 获取查询条件
    onSelect(action){
      this.actionText = action.text;
      this.params.infoId = action.id;
      this.params.page = 0;
      this.getList();
    },
    // 获取所有酒店信息
    getHotel(){
      this.actions = [
        {id:'',text:'全部酒店'}
      ];
      return new Promise(resolve => {
        this.$http({
          url: '/zuul/cultural/raw',
          method: 'get',
          params:{
            type:'11'
          }
        }).then(result => {
          if(result.data.success){
            result.data.data.map(item=>{
              item.text = item.name;
              if(!this.hotels[item.id]){
                this.hotels[item.id] = item;
              }
              this.actions.push(item)
            })
            resolve(true)
          }
        })
      })
    },
    // 获取列表
    getList() {
      this.finished =false;
      this.loading = true;
      let _this = this;
      this.list = [];
      this.$http({
        url: "/zuul/cultural/customerHealth",
        method: "get",
        params: {
          ...this.params,
        }
      }).then(res => {
        // console.log(res)
        if (res.data.success && res.data.data) {
          _this.list = res.data.data.results;
          _this.total = res.data.data.total;
          _this.list.map(item=>{
            _this.$set(this.checked,item.id,false);
          })
        }
        if(_this.list.length<this.total){
          this.params.page++;
        }
        this.isFinished();
      }).finally(response => {
        this.loading = false;
      })
    },
    // 判断是否加载完成
    isFinished(){
      if(this.list.length>=this.total){
        this.finished = true;
        this.refreshing = false;
      }
    },
    // 下拉加载
    onLoad(){
      let _this = this;
      Promise.all([this.getOnLoadList()]).then(res=>{
        if(res[0].data.success && res[0].data.data.results){
          if(this.params.page>0){
            _this.list = _this.list.concat(res[0].data.data.results)
          }else{
            _this.list = res[0].data.data.results;
          }
          if(_this.list.length>= _this.total || res[0].data.data.results.length==0){
            _this.finished = true;
          }else{
            _this.params.page++;
          }
        }
      })
    },
    getOnLoadList(){
      this.loading = true;
      return new Promise(resolve => {
        this.$http({
          url: "/zuul/cultural/customerHealth",
          method: "get",
          params: this.params
        }).then(res=>{
          resolve(res);
        }).catch(e=>{
          this.error = true;
        }).finally(e=>{
          this.loading = false;
        })
      })
    },
    // 修改时间条件
    timeChange(name, title){
      this.params.page = 0;
      this.total = 0;
      this.list = [];
      if(name==1){
        this.params.startTime = getWeekEndDate()[0];
        this.params.endTime = getWeekEndDate()[1];
        this.getList();
      }else if(name==2){
        this.params.startTime = getStartEndByMonth()[0];
        this.params.endTime = getStartEndByMonth()[1];
        this.getList();
      }else if(name==0){
        this.params.startTime = formatDate(new Date()) + " 14:00:00";
        this.params.endTime = this.addOneDay(new Date()) + " 14:00:00";
        this.getList();
      }else if(name==3){
        this.calendarShow = true;
      }
      console.log(this.params.startTime,this.params.endTime)
    },
    // 自定义时间选择
    onConfirm(date) {
      this.params.page = 0;
      let [start, end] = date;
      this.calendarShow = false;
      this.params.startTime =this.formatDate(start) + " 14:00:00";
      this.params.endTime =this.addOneDay(end) + " 14:00:00";
      this.getList();
    },
    // 时间格式化
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    // 时间加一天
    addOneDay(date){
      let newD = new Date(date);
      return `${newD.getFullYear()}-${newD.getMonth() + 1}-${newD.getDate()+1}`;
    },
    // 导出表格
    downLoad(type){
      let url = "/zuul/cultural/customerHealth/export?";
      if(type==0){
        let start = formatDate(new Date()) + " 14:00:00";
        let end = this.addOneDay(new Date())+ "  14:00:00";
        url = url + "&startTime="+ start  + "&endTime=" + end ;
      }else if(type==1){
        let start = getWeekEndDate()[0];
        let end = getWeekEndDate()[1];
        url = url + "&startTime=" + start  + "&endTime=" + end ;

      }else if(type==2){
        let start = getStartEndByMonth()[0];
        let end = getStartEndByMonth()[1];
        url = url + "&startTime="+ start  + "&endTime=" + end ;

      }else{
        let ids = []
        for(let k in this.checked){
          if(this.checked[k]){
            ids.push(k)
          }
        }
        if(ids.length==0){
          Notify({ type: 'warning', message: '至少选择一项！' });
          return;
        }
        url = url + "&ids="+ ids.join(",");
      }
      window.open(url,"blank")
    },
    onSearch(value){
      if(this.actionText=='姓名'){
        this.params.name = this.searchKey;
        this.params.travelCities = "";
      }else{
        this.params.travelCities = this.searchKey;
        this.params.name = "";
      }
      this.params.page = 0;
      this.getList();
    },
    // 解析结果
    parseResult(item){

    },
    // 查看详情
    viewDetail(item){
         console.log("edit",item)
        this.recordTitle = "酒店客人健康信息";
        this.recordData = item;
        this.recordPopup = true;
    },
    add(){
      this.recordData = null;
      this.recordPopup = true;
    },
    closeReport(){
      this.getList();
      this.recordPopup = false;
    }
  },
  mounted(){
    this.params.page = 0;
    this.timeChange(0);
    Promise.all([this.getHotel()]).then(res=>{
        this.getList();
    })
  }
}
</script>
<style>
.van-popover__action{
  width: 100% !important;
}
</style>
<style scoped lang="less">
  /deep/ .van-button--default{
    border:1px solid #ebedf0;
  }


  /deep/ .van-button__content{
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  /deep/ .van-button {
    font-size: 14px;
  }
  /deep/ .van-popover__action,/deep/ .van-popover__wrapper{
    width: calc(100vw) !important;
  }

    .searchTab{
    position: absolute;
    left: 12px;
    top: 20px;
  }
  .des{
    padding: 10px 20px;
    color: rgba(69, 90, 100, 0.6);
    background: #f7f8fa;
  }
  .list-btn{
    //display: none;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: calc(100vw);
  }
  .add-btn{
    position: fixed;
    bottom: 60px;
    left: 0px;
    width: calc(100vw - 64px);
    padding: 0px 32px;
  }
  .list-content{
    padding-bottom: 120px;
  }
  .check-list {
    padding: 10px 10px 2px;
    font-size: 0.8rem;
    border-bottom: 1px solid #ebedf0;;

    >div>div {
      display: inline-block;
    }

    .item-choose {
      width: 18px;
      vertical-align: middle;
    }

    .item-name{
      width: 90px;
    }

    .item-info{
      width: calc(100vw - 40px);
    }
    .item-tag{
      width: 40px;
      text-align: center;
    }
    .item-btn{
      width: calc(100vw - 280px);
      text-align: right;
      position: relative;
      top: 2px;
      padding-left: 5px;
      padding-right: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .item-click{
        position: absolute;
        right: 0px;
        top: 2px;
      }
    }

    .item-check {
      padding-left: 10px;
      width: 100px;
      line-height: 1.2rem;
      vertical-align: middle;

      .item-user {
        padding: 5px 0px 2px 0px;

        .van-icon {
          position: relative;
          top: 2px;
          margin-right: 2px;
        }
      }
    }
  }
  .model-title{
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
</style>